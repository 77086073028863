.tag--dc-fast-charger {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &--image {
    width: 36px;
    height: 38px;
  }

  span {
    padding: 0 35px 0 5px;
    margin-top: 10px;
  }
}
