html[data-theme='light-retail'] {
  .page {
    padding: 0 2.5%;

    &__header {
      width: 100%;
      display: flex;

      &-img {
        padding-right: 24px;
      }

      &-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page__chargingLocations &,
        .page__confirmDetails & {
          align-items: flex-start;
        }
      }

      &-title {
        padding-top: 4px;
      }

      &-tags {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }

    &__card {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: grid;
      grid-template-columns: auto 306px;
      gap: 10px;

      &-title {
        display: flex;
        padding-bottom: 0.25rem;

        img {
          height: 60px;
          width: 50px;
        }

        h2 {
          align-self: end;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          padding-right: 20px;
          white-space: nowrap;
        }
      }

      &-btn {
        padding-right: 60px;
      }

      &-body {
        width: 100%;
        padding: 0 60px 0 60px;
      }
    }
  }

  .activate-button {
    height: 62px;
    width: 260px;
    padding-left: 70px;
  }

  .container-activate {
    width: 100% !important;
    height: 100% !important;
  }

  ._flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-button {
    @extend .button--secondary;
    @extend ._flex-center;
    height: 62px;
    width: 307px;
  }

  .select-button-plug-in {
    @extend ._flex-center;
    height: 62px;
    width: 307px;
  }
  .select-button-disabled {
    @extend .select-button;
    @extend .button--disabled;
    min-width: 307px;
    pointer-events: none;
    color: var(--font-color-senary) !important;
  }

  .pointer-event {
    pointer-events: all;
  }
}

html[data-theme='dark-retail'] {
  .page {
    padding: 0 2.5%;

    &__header {
      width: 100%;
      display: flex;

      &-img {
        padding-right: 24px;
      }

      &-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page__chargingLocations &,
        .page__confirmDetails & {
          align-items: flex-start;
        }
      }

      &-title {
        padding-top: 4px;
      }

      &-tags {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }

    &__card {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: grid;
      grid-template-columns: auto 306px;
      gap: 10px;

      &-title {
        display: flex;
        padding-bottom: 0.25rem;

        img {
          height: 60px;
          width: 50px;
        }

        h2 {
          align-self: end;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          padding-right: 20px;
          white-space: nowrap;
        }
      }

      &-btn {
        padding-right: 60px;
      }

      &-body {
        width: 100%;
        padding: 0 60px 0 60px;
      }
    }
  }

  .activate-button {
    height: 62px;
    width: 260px;
    padding-left: 70px;
  }

  .container-activate {
    width: 100% !important;
    height: 100% !important;
  }

  ._flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-button {
    @extend .button--secondary;
    @extend ._flex-center;
    height: 62px;
    width: 307px;
  }

  .select-button-disabled {
    @extend .select-button;
    @extend .button--disabled;
    min-width: 307px;
    pointer-events: none;
    color: var(--font-color-senary);
  }

  .pointer-event {
    pointer-events: all;
  }
}
