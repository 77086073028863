.spinner-main-container {
  background: linear-gradient(#1b222b, #13181f);
  display: 'block';
  z-index: 10 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.96;
  top: 12%;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loading-text {
  font-size: 46px;
  color: #c3cde5;
  margin-bottom: 55px;
}
