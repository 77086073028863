//
//  Dividers
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .spacer-vertical {
    margin: 0;
    padding: 0;
    height: 25px;
  }

  .spacer-horizontal {
    margin: 0px;
    padding: 0px;
    width: 35px;
  }

  .primary-divider {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--font-color-quinary);
    width: 100%;
    margin: 39px 0px 31px -15px;
  }

  .secondary-divider {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 100%;
    margin: 25px auto;
    position: relative;
  }

  .secondary-divider-landing-page {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 49%;
    margin-bottom: 20px;
  }

  .secondary-divider-accordion {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 100%;
    margin-bottom: 20px;
    margin-right: 12%;
    margin-left: 8%;
  }

  .mutedBlue-divider {
    @extend .primary-divider;
    margin: 39px 0px 31px -15px;
  }

  .blue-divider {
    @extend .secondary-divider;
    background-color: var(--font-color-quinary);
    margin: 45px auto;
  }
}

html[data-theme='dark-retail'] {
  .spacer-vertical {
    margin: 0;
    padding: 0;
    height: 25px;
  }

  .spacer-horizontal {
    margin: 0px;
    padding: 0px;
    width: 35px;
  }

  .primary-divider {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--font-color-quinary);
    width: 100%;
    margin: 39px 0px 31px -15px;
  }

  .secondary-divider {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 100%;
    margin: 25px auto;
    position: relative;
  }

  .secondary-divider-landing-page {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 49%;
    margin-bottom: 20px;
  }

  .secondary-divider-accordion {
    height: 1px;
    transform: scaleX(-1);
    background-color: var(--border-color-primary);
    width: 100%;
    margin-bottom: 20px;
    margin-right: 12%;
    margin-left: 8%;
  }

  .mutedBlue-divider {
    @extend .primary-divider;
    margin: 39px 0px 31px -15px;
  }

  .blue-divider {
    @extend .secondary-divider;
    background-color: var(--font-color-quinary);
    margin: 45px auto;
  }
}
