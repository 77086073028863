.three-dot-spinner-container {
  background: #3d3d3e;
  z-index: 10 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.96;
}

.loadingContainer {
  height: 20px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation: loadingAnimation 1.5s infinite linear;
  will-change: opacity;
  background-color: rgb(159, 163, 181);
}

.loading:nth-child(2) {
  animation-delay: 0.15s;
  margin-left: 15px;
}

.loading:nth-child(3) {
  animation-delay: 0.3s;
  margin-left: 15px;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
