//
//  Global Style Variables
//  ---------------------------------------------

//
//  Fonts
//  ---------------------------------------------
$base-font: 'Ford Antenna', Arial, Helvetica, sans-serif;
$base-font-condensed: 'Ford Antenna Cond', Arial, Helvetica, sans-serif;
$base-font-condensed-med: 'FordAntennaCond-Medium', Arial, Helvetica, sans-serif;
$base-font-condensed-light: 'FordAntennaCond-Light', Arial, Helvetica, sans-serif;
$base-font-condensed-extra-light: 'Ford Antenna Cond-extralight', Arial, Helvetica, sans-serif;
$code-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

//
//  Colors
//  ---------------------------------------------
$white: #ffffff;
$lightestGrey: #f2f2f2;
$lightGrey: #efefef;
$grey: #c8c8c8;
$mediumGrey: #979797;
$darkGrey: #6e6e6e;
$darkestGrey: #4d4d4d;
$black: #000000;
$lightBlue: #2861a4;
$mutedBlue: #44516f;
$brightBlue: #0142bd;
$blue: #102b4e;
$green: #008200;
$orange: #da7029;
$red: #d62d0a;
$primaryBlueDarkMode: #0093f0;
$secondaryBlueDarkMode: #0275eb;
$successGreenDarkMode: #29a429;
$errorDarkMode: #ff3030;
$secondaryGrayDarkMode: #8c8c8c;
$nobel: #b7b3b3;
$santasGray: #9fa3b5;
$primaryRetailBG: #efeef7;
$primaryDarkRetail: #1b1f25;
$primaryTextLightRetail: #44516f;
$primaryTextDarkRetail: #9fa3b5;
$greenLightMode: #008200;
$greenDarkMode: #29a429;
$redLightMode: #d62d0a;
$redDarkMode: #ff3030;
$stallsLightMode: #6e6e6e;
$stallsDarkMode: #a8a8a8;
$primaryRetailBlueCrayola: #126fea;
$disabledcolorLight: #6e6e6e38;
$lightButton: #f1f0f7;
$secondaryDarkRetail: #1b1f24;
$secondaryWhiteLightMode: #e2e2e2;
$secondaryGrayDarkMode: #a8a9aa;

:root,
html[data-theme='light-retail'] {
  --arrow-up: #{$brightBlue};
  --arrow-down: #{$blue};
  --font-color-primary: #{$primaryTextLightRetail};
  --font-color-secondary: #{$blue};
  --font-color-tertiary: #{$white};
  --font-color-quaternary: #{$lightestGrey};
  --font-color-quinary: #{$mutedBlue};
  --font-color-senary: #{$secondaryWhiteLightMode};
  --alert-disabled: #{$darkGrey};
  --alert-success: #{$greenLightMode};
  --alert-warning: #{$orange};
  --instructions-text: #ba4e00;
  --alert-error: #{$redLightMode};
  --background-color-primary: #{$primaryRetailBG};
  --background-color-secondary: #{$white};
  --background-color-tertiary: #{$blue};
  --background-color-quaternary: #{$darkGrey};
  --btn-primary-color: #{$mutedBlue};
  --help-modal-header: #{$primaryRetailBG};
  --help-modal-header-text: #{$mutedBlue};
  --help-modal-instruction-block-bg: #f8f8ff;
  --help-modal-scroll-color: #{$mutedBlue};
  --btn-primary-bkgd-color: transparent;
  --btn-primary-drop-shadow: 0 -10px 15px 0 rgba(255, 255, 255, 0.75),
    0 10px 10px 0 rgba(0, 0, 0, 0.15);
  --btn-secondary-color: #{$primaryTextLightRetail};
  --btn-secondary-bkgd-color: linear-gradient(
    180deg,
    #{$brightBlue} 0%,
    #{$secondaryBlueDarkMode} 100%
  );
  --btn-secondary-drop-shadow: inset 0 5px 8px 0 rgb(16, 43, 78, 0.5),
    0 -10px 10px 0 rgba(255, 255, 255, 0.5), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  --btn-bkgd-color-primary: #{$blue};
  --btn-bkgd-color-hover-primary: #{$lightBlue};
  --btn-bkgd-color-secondary: transparent;
  --btn-bkgd-color-hover-secondary: #{$lightestGrey};
  --btn-border-color-secondary: #{$blue};
  --btn-bkgd-color-tertiary: #{$white};
  --btn-bkgd-color-hover-tertiary: #{$white};
  --btn-border-color-tertiary: #{$blue};
  --btn-border-radius: 45px;
  --btn-filter-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.03),
    0 5px 5px -3px rgba(0, 0, 0, 0.05);
  --border-color-primary: #{$grey};
  --box-shadow-color-primary: #{$nobel};
  --box-shadow-color-secondary: #{$white};
  --modal-overlay: rgba(255, 255, 255, 0.5);
  --modal-box-shadow: 0 -2px 10px 3px rgba(0, 0, 0, 0.1), 0 75px 35px -40px rgba(0, 0, 0, 0.5);
  --modal-header-shadow: #dee2e6 0px 25px 18px -17px;
  --progress-box-shadow: #{$white} 0.2rem 0.2rem 0.5rem, #{$nobel} -0.2rem -0.2rem 0.5rem;
  --toogle-background-color: #dddde5;
  --disabled-background-color: #{$darkGrey};
  --ca-retail-accordion-text: #{$mutedBlue};
  --retail-link-color-primary: #{$primaryRetailBlueCrayola};
  --secondary-gray: #{$darkGrey};
  --button-secondary-bkgd-color: #{$lightButton};
  --box-shadow-color-secondary: 0 -10px 10px hsla(0, 0%, 100%, 0.502),
    0 10px 10px rgba(0, 0, 0, 0.15);

  --progress-background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 248, 248, 1) 0%,
    rgba(228, 231, 235, 1) 37%,
    rgba(177, 178, 181, 1) 59%,
    rgba(207, 208, 210, 0) 100%
  );

  .light-icon {
    display: initial;
  }

  .light-section {
    display: flex;
  }

  .dark-icon {
    display: none;
  }

  .dark-section {
    display: none;
  }

  .dark-retail-icon {
    display: none;
  }

  .light-retail-icon {
    display: initial;
  }

  .charger-availability-unknown {
    color: #{$stallsLightMode};
  }

  --ca-retail-btn-text: #{$white};
  --ca-retail-primary: #{$mutedBlue};
  --ca-retail-success: #{$green};
  --ca-retail-error: #{$redLightMode};
  --ca-retail-gray: #{$darkGrey};
  --ca-retail-secondary: #0454bd;
  --ca-retail-divider: #bfbec6;
  --ca-retail-white: #{$white};
  --ca-retailr-progressbar-newly-added-percentage: #6aabff;
  --ca-retil-percentage-divider: #777779;
  --septenary-font-color: #{$primaryTextLightRetail};
  --light-grey: #{$lightGrey};
}

html[data-theme='dark-retail'] {
  --arrow-up: #{$primaryBlueDarkMode};
  --arrow-down: #{$secondaryBlueDarkMode};
  --font-color-primary: #{$primaryTextDarkRetail};
  --font-color-secondary: #{$primaryBlueDarkMode};
  --font-color-tertiary: #{$primaryTextLightRetail};
  --font-color-quaternary: #{$lightestGrey};
  --font-color-quinary: #{$lightestGrey};
  --font-color-senary: #{$secondaryGrayDarkMode};
  --alert-disabled: #{$darkGrey};
  --alert-success: #{$greenDarkMode};
  --alert-warning: #{$orange};
  --instructions-text: #ba4e00;
  --alert-error: #{$redDarkMode};
  --background-color-primary: #{$primaryDarkRetail};
  --background-color-secondary: #{$primaryTextLightRetail};
  --background-color-tertiary: #{$blue};
  --background-color-quaternary: #{$darkGrey};
  --btn-primary-color: #{$lightestGrey};
  --help-modal-header: #{$primaryDarkRetail};
  --help-modal-header-text: #{$santasGray};
  --help-modal-instruction-block-bg: #212530;
  --help-modal-scroll-color: #{$santasGray};
  --btn-primary-bkgd-color: transparent;
  --btn-primary-drop-shadow: inset 0 -5px 8px 0 rgb(0 0 0 / 40%),
    inset 0 5px 8px 0 rgb(52 60 77 / 40%), 0 1px 0 0 #000;
  --btn-secondary-color: #{$primaryTextDarkRetail};
  --btn-secondary-bkgd-color: linear-gradient(
    180deg,
    #{$brightBlue} 0%,
    #{$secondaryBlueDarkMode} 100%
  );
  --btn-secondary-drop-shadow: 0 -6px 15px 0 rgb(92 156 225 / 40%), 0 10px 10px 0 rgba(0, 0, 0, 0.5);
  --btn-bkgd-color-primary: #{$blue};
  --btn-bkgd-color-hover-primary: #{$lightBlue};
  --btn-bkgd-color-secondary: transparent;
  --btn-bkgd-color-hover-secondary: #{$lightestGrey};
  --btn-border-color-secondary: #{$blue};
  --btn-bkgd-color-tertiary: #{$primaryTextLightRetail};
  --btn-bkgd-color-hover-tertiary: #{$primaryTextLightRetail};
  --btn-border-color-tertiary: #{$blue};
  --btn-border-radius: 45px;
  --btn-filter-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.03),
    0 5px 5px -3px rgba(0, 0, 0, 0.05);
  --border-color-primary: #{$grey};
  --box-shadow-color-primary: #{$nobel};
  --box-shadow-color-secondary: #{$primaryTextLightRetail};
  --modal-overlay: rgba(255, 255, 255, 0.5);
  --modal-box-shadow: 0 -2px 10px 3px rgba(0, 0, 0, 0.1), 0 75px 35px -40px rgba(0, 0, 0, 0.5);
  --modal-header-shadow: 0 10px 15px 0 rgb(92 156 225 / 30%), 0 10px 10px 0 rgba(0, 0, 0, 0.5);
  --secondary-gray: #{$stallsDarkMode};
  --gray-divider: #{$darkestGrey};
  --progress-box-shadow: 0.1rem 0.5rem 0.1rem #0e141a, 0.1rem -0.5rem 0.1rem #0e141a;
  --progress-background: rgb(33, 40, 48);
  --floating-input-shadow: 0 -10px 15px 0 #{'hsl(0deg 6% 5% / 75%)'},
    0 4px 10px 0 rgba(92, 156, 225, 0.3);
  --autosuggest-boxshadow: 0 -10px 15px 0 rgba(92, 156, 225, 0.3),
    0 4px 10px 0 rgba(92, 156, 225, 0.3);
  --toogle-switch-boxshadow: 0 10px 15px 0 rgba(92, 156, 225, 0.3), 0 10px 10px 0 rgba(0, 0, 0, 0.5);
  --disabled-background-color: #{$darkGrey};
  --btn-primary-color: #{$santasGray};
  --retail-link-color-primary: #{$primaryRetailBlueCrayola};
  --ca-retail-accordion-text: #{$white};
  --button-secondary-bkgd-color: #{$secondaryDarkRetail};
  --box-shadow-color-secondary: 0 10px 10px 0 #000, 0 -10px 10px 0 #293443;

  .dark-icon {
    display: initial;
  }

  .light-icon {
    display: none;
  }

  .dark-section {
    display: flex;
  }

  .light-section {
    display: none;
  }

  .dark-retail-icon {
    display: initial;
  }

  .light-retail-icon {
    display: none;
  }

  .charger-availability-unknown {
    color: #{$stallsDarkMode};
  }

  --ca-retail-btn-text: #{$primaryTextLightRetail};
  --ca-retail-primary: #{$santasGray};
  --ca-retail-success: #{$successGreenDarkMode};
  --ca-retail-error: #{$errorDarkMode};
  --ca-retail-gray: #a8a8a8;
  --ca-retail-secondary: #30a9ff;
  --ca-retail-white: #{$white};
  --ca-retail-divider: #3b465f;
  --ca-retailr-progressbar-newly-added-percentage: #2282ff;
  --septenary-font-color: #{$primaryTextDarkRetail};
  --light-grey: #{$lightGrey};
}
