.text-header {
  height: auto;
  line-height: normal;
  white-space: normal;
  margin-top: 10px;
}

.mutedBlue-divider-medium {
  @extend .primary-divider;
  margin: 39px 0px 31px -15px;
}

.charging-locations-container {
  .page__card-item {
    padding-left: 56px;
  }
}

.star {
  margin-left: -17px;
  margin-right: -17px;
  scale: 65%;
}

.single-charger {
  width: 100%;
  color: var(--font-color-primary);
}

.out-of-network {
  color: var(--font-color-primary);
}

.max-speed,
.location-name {
  color: var(--font-color-secondary);
}

.card-body {
  margin-top: 3rem;
}

.child-charger-item {
  .page__card-info {
    .page__card-title {
      h2 {
        padding-left: 56px;
        font-size: 28px;
      }
    }
  }
}

.firstAccordion--open {
  margin-top: -13px !important;
}

html[data-theme='light-retail'],
html[data-theme='dark-retail'] {
  .charging-locations-container {
    padding: 0px 55px;
    div[data-id='score'] {
      .MuiRating-root {
        height: 78px;
      }
    }

    .header {
      margin: 90px 0 24px;
      align-items: center;
      color: var(--ca-retail-primary);
      .header-label {
        display: flex;
        gap: 32px;
        align-items: center;
        font-weight: 600;
      }
    }
    .header-only {
      grid-template-columns: auto;
    }
    .ca-divider {
      border-bottom: 1px solid var(--ca-retail-divider);
      margin-bottom: 30px;
    }
    .body {
      .charger-speed-icon {
        margin-top: 0px;
      }
      .stall--speed {
        img {
          padding-bottom: 5px;
        }
      }
      .stall-properties {
        display: flex;
        align-items: center;
        gap: 20px;

        .tag--plug-n-charge {
          font-size: 34px !important;
          margin: unset;
          padding-bottom: 6px;
        }
      }
      .location-details {
        .location-total-availability {
          margin-bottom: 56px;
          display: flex;
        }

        .location-info {
          margin-bottom: 104px;
        }

        .location-arrived {
          margin-bottom: 60px;
          display: flex;
          grid-template-columns: 300px 400px;
          gap: 48px;
        }
      }

      .blur-charger-item {
        opacity: 0.5;
      }

      .address-container {
        margin-bottom: 24px;
      }

      .charging-propgres-bar {
        display: grid;
        grid-template-columns: 64px auto 64px;
        min-height: 152px;
      }

      .charger-stalls-body:only-child {
        border-bottom: 1px solid var(--font-color-quinary);
      }

      .charger-stalls-body + .charging-speed {
        border-bottom: 1px solid var(--font-color-quinary);
      }

      .charging-speed {
        padding-bottom: 16px;
      }

      .charger-stalls {
        margin-bottom: 16px;
        padding: 32px 16px 24px 32px;
        display: grid;
        grid-template-columns: 104px auto 308px;

        .stall-properties {
          display: grid;
          grid-template-columns: 200px 100px auto;
          gap: 1px 20px;
          margin-top: 12px;
          align-items: baseline;

          .stall-price {
            .connector-price-container {
              height: auto;
            }
          }
        }
      }

      .stall-status {
        padding: 0px 16px 24px 0px;
      }
    }

    .stall-details {
      .label,
      .stall-speed,
      .stall-price,
      .out-of-network-msg {
        color: var(--ca-retail-gray);
      }
    }

    .charger-stalls-label {
      margin-left: 24px;
      color: var(--ca-retail-accordion-text);
    }

    .disclaimer-text {
      font-size: 34px;
      font-family: $base-font-condensed;
      font-weight: 500;
      color: var(--retail-link-color-primary);
      letter-spacing: 2.43px;
      margin: 128px 0px 30px 0px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.gained-miles,
.speeds {
  color: var(--ca-retail-gray);
  margin-bottom: 0;
}

.speeds-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.charging-speed-text {
  font-size: 34px;
  font-family: $base-font-condensed;
  font-weight: 500;
  color: var(--retail-link-color-primary);
  letter-spacing: 2.43px;
  margin: 128px 0px 30px 0px;
  text-transform: uppercase;
  cursor: pointer;
}
