html[data-theme='light-retail'] {
  .tag--plug-n-charge {
    @extend %tag-base;
    @extend %tag-pNc-base;
    height: 48;
    font-weight: 400;
    margin-bottom: auto;
  }
}

html[data-theme='dark-retail'] {
  .tag--plug-n-charge {
    @extend %tag-base;
    @extend %tag-pNc-base;
    height: 48;
    font-weight: 400;
    margin-bottom: auto;
  }
}

.plud-n-charge-padding-img {
  padding-right: 10px;
}
