//
//  Bootstrap Card Styles
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .card {
    border: none !important;
    width: 100%;
    padding-right: 8px;
    background-color: var(--background-color-primary);
    border-radius: 0;

    &.accordion--open {
      margin: -46px 0 -46px -20px;
      padding: 20px 8px 0 20px;
      width: calc(100% + 20px);
      z-index: 1;
      box-shadow: var(--box-shadow-color-secondary) 0 8px 5px -4px,
        inset var(--box-shadow-color-primary) 0px 2px 5px 0px;
    }
  }

  .card-header {
    border: none;
    background-color: var(--background-color-primary);
    padding: 0 !important;
  }

  .card-body {
    background-color: var(--background-color-primary);
    padding: 0 !important;
  }
}

html[data-theme='dark-retail'] {
  .card {
    border: none !important;
    width: 100%;
    padding-right: 8px;
    background-color: var(--background-color-primary);
    border-radius: 0;

    &.accordion--open {
      margin: -46px 0 -46px -20px;
      padding: 20px 8px 0 20px;
      width: calc(100% + 20px);
      z-index: 1;
      box-shadow: var(--box-shadow-color-secondary) 0 8px 5px -4px,
        inset var(--box-shadow-color-primary) 0px 2px 5px 0px;
    }
  }

  .card-header {
    border: none;
    background-color: var(--background-color-primary);
    padding: 0 !important;
  }

  .card-body {
    background-color: var(--background-color-primary);
    padding: 0 !important;
  }
}
