.offline-modal {
  min-height: 450px;
  .modal__body {
    .modal__body-content {
      padding-bottom: 40px;
    }
    min-height: 250px;
  }
  .button--secondary {
    padding: 11px 92px 9px 88px;
  }
}
