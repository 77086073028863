.tag--in-network {
  display: flex;
  align-items: center;

  &-tag {
    @extend %tag-base;
    padding: 2px 20px;
    margin-right: 37px;
  }

  p {
    margin-bottom: 0;
  }
}
