//
//  Alignment Styles
//  ---------------------------------------------

// positioning

html[data-theme='light-retail'] {
  .center-vertically {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
  }

  // flexbox alignment
  .flex-align-center {
    align-items: center;
  }

  //application right margin.
  .offset-md-right-1 {
    margin-left: -2.233333%;
  }
}

html[data-theme='dark-retail'] {
  .center-vertically {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
  }

  // flexbox alignment
  .flex-align-center {
    align-items: center;
  }

  //application right margin.
  .offset-md-right-1 {
    margin-left: -2.233333%;
  }
}
