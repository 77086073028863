//
//  Bootstrap Override Styles
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .col-12 {
    padding: 0;
  }

  .container-fluid {
    padding-bottom: 80px;
    z-index: 1;
  }

  .btn {
    float: right;
  }

  .offset-md-1 {
    margin-left: 2.5%;
  }
}

html[data-theme='dark-retail'] {
  .col-12 {
    padding: 0;
  }

  .container-fluid {
    padding-bottom: 80px;
    z-index: 1;
  }

  .btn {
    float: right;
  }

  .offset-md-1 {
    margin-left: 2.5%;
  }
}
