@font-face {
  font-family: 'Ford Antenna';
  src: url('../../../common/assets/fonts/antenna-regular.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-regular.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-regular.woff2?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-regular.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-regular.svg#web?v=2') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: 'Ford Antenna-Bold';
  src: url('../../../common/assets/fonts/antenna-bold.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-bold.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-bold.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-bold.svg#web?v=2') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: 'Ford Antenna-Light';
  src: url('../../../common/assets/fonts/antenna-light.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-light.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-light.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-light.svg#web?v=2') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: 'FordAntennaExtraLight';
  src: url('../../../common/assets/fonts/FordAntennaWGL-ExtraLight.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Ford Antenna Cond';
  src: url('../../../common/assets/fonts/antenna-cond-regular.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-cond-regular.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-cond-regular.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-cond-regular.svg#web?v=2') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: 'Ford Antenna Cond-extralight';
  src: url('../../../common/assets/fonts/antenna-cond-extra-light.eot?v=2')
      format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-cond-extra-light.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-cond-extra-light.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-cond-extra-light.svg#web?v=2') format('svg');
  font-weight: 300;
}

@font-face {
  font-family: 'FordAntennaCond-Light';
  src: url('../../../common/assets/fonts/antenna-cond-light.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-cond-light.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-cond-light.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-cond-light.svg#web?v=2') format('svg');
  font-weight: 300;
}

@font-face {
  font-family: 'FordAntennaCond-Medium';
  src: url('../../../common/assets/fonts/antenna-cond-medium.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-cond-medium.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-cond-medium.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-cond-medium.svg#web?v=2') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: 'FordAntennaCond-Bold';
  src: url('../../../common/assets/fonts/antenna-cond-bold.eot?v=2') format('embedded-opentype'),
    url('../../../common/assets/fonts/antenna-cond-bold.woff?v=2') format('woff'),
    url('../../../common/assets/fonts/antenna-cond-bold.ttf?v=2') format('truetype'),
    url('../../../common/assets/fonts/antenna-cond-bold.svg#web?v=2') format('svg');
  font-weight: normal;
}
