//
//  Nav Bar
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .navbar-default {
    height: 102px;
    box-sizing: border-box;
    background-color: var(--background-color-primary);
  }
}

html[data-theme='dark-retail'] {
  .navbar-default {
    height: 102px;
    box-sizing: border-box;
    background-color: var(--background-color-primary);
  }
}
