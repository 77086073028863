html[data-theme='light-retail'],
html[data-theme='dark-retail'] {
  .plugInContainer .button--secondary {
    background: none;
    padding: 0px !important;
    height: 62px;
    width: 307px;
    box-shadow: none;
    color: var(--septenary-font-color);
  }

  .plug-icon {
    padding-right: 8px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
}
