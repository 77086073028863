//
//  Bootstrap Accordion Styles
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .accordion-arrow {
    float: right;
  }
}

html[data-theme='dark-retail'] {
  .accordion-arrow {
    float: right;
  }
}
