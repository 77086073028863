//
//  Footer
//  ---------------------------------------------

html[data-theme='light-retail'] {
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid var(--border-color-primary);
    background-color: var(--background-color-primary);
    height: 68px;
  }
}

html[data-theme='dark-retail'] {
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid var(--border-color-primary);
    background-color: var(--background-color-primary);
    height: 68px;
  }
}
