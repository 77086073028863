.toggle-switch {
  display: inline-block;
  width: 100px;
  height: 50px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0 -10px 10px 0 rgba(255, 255, 255, 0.5), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider:before {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  left: 7px;
  bottom: 5px;
  background-color: var(--toogle-background-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background: linear-gradient(180deg, #0275eb 0%, #0142bd 100%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(47px);
  -ms-transform: translateX(47px);
  transform: translateX(47px);
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 35px;
  box-shadow: inset 0 5px 8px 0 rgba(0, 0, 0, 0.4), inset 0 -3px 8px 0 rgba(92, 156, 225, 0.25);
}

html[data-theme='dark-retail'] .toggle-switch {
  box-shadow: var(--toogle-switch-boxshadow);
}
