//
//  Typographys Styles
//  ---------------------------------------------

// style extends

html[data-theme='light-retail'] {
  %fordAntennaCond {
    font-family: $base-font-condensed;
    line-height: 48px;
    letter-spacing: 2.43px;
  }

  %fordAntennaCondMed {
    font-family: $base-font-condensed-med;
    letter-spacing: 2.43px;
    line-height: 1.2;
  }

  // text color class names
  .text-gray {
    color: var(--font-color-primary);
  }

  .text-dark-gray {
    color: var(--secondary-gray);
  }

  .text-green {
    color: var(--alert-success);
  }

  .text-orange {
    color: var(--alert-warning);
  }

  .text-red {
    color: var(--alert-error);
  }

  .text-blue {
    color: var(--font-color-primary);
  }

  .text-disabled {
    color: var(--alert-disabled);
  }

  .text-dark-orange {
    color: var(--instructions-text);
  }

  .text-blue-muted {
    color: var(--font-color-quinary);
  }

  .text-light-gray {
    color: #767676;
  }

  // text styles
  .text-base--16 {
    @extend %fordAntennaCond;
    font-size: 16px;
  }

  .text-base--18 {
    @extend %fordAntennaCond;
    font-size: 18px;
  }

  .text-base--20 {
    @extend %fordAntennaCond;
    font-size: 20px;
  }

  .text-base--22 {
    @extend %fordAntennaCond;
    font-size: 22px;
  }

  .text-base--26 {
    @extend %fordAntennaCond;
    font-size: 26px;
  }

  .text-base--31 {
    @extend %fordAntennaCond;
    font-size: 31px;
  }

  .text-base--34 {
    @extend %fordAntennaCond;
    font-size: 34px;
  }

  .text-base--34--bolded {
    @extend %fordAntennaCondMed;
    font-size: 34px;
  }

  .text-base--40 {
    @extend %fordAntennaCond;
    font-size: 40px;
  }

  .text-base--40--bolded {
    @extend %fordAntennaCondMed;
    font-size: 40px;
  }

  .text-base--36 {
    @extend %fordAntennaCond;
    font-size: 36px;
    line-height: 55px;
    letter-spacing: 0px;
  }

  .text-base--36--bolded {
    @extend %fordAntennaCondMed;
    font-size: 36px;
  }

  .text-base--46 {
    @extend %fordAntennaCond;
    font-size: 46px;
    line-height: 1.2;
    letter-spacing: normal;
  }

  .filter-text {
    @extend %fordAntennaCond;
    font-size: 34px;
    letter-spacing: 0px;
    text-transform: capitalize;
  }

  .filter-sub-text {
    @extend %fordAntennaCond;
    font-size: 24px;
    letter-spacing: 0px;
    margin-left: 56px;
    white-space: nowrap;
  }

  // headline styles
  .text-headline {
    @extend %fordAntennaCond;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 2.43px;
    line-height: initial;
    padding-bottom: 0px;
  }

  .text-headline--padding-40 {
    @extend .text-headline;
    padding-bottom: 40px;
  }

  .text-headline--padding-30 {
    @extend .text-headline;
    padding-bottom: 30px;
  }

  //search input font styles
  .search-input-text {
    color: var(--font-color-quinary);
    font-family: $base-font-condensed;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 55px;
    font-weight: 500;
    height: 70px !important;
    padding-left: 20px;
    text-transform: none;
  }

  .text-error {
    @extend .text-base--40--bolded;
    @extend .text-red;
    text-align: center;
    margin-bottom: 35px;
  }

  .mutedBlue-divider-small {
    @extend .mutedBlue-divider;
    margin-top: 16px;
  }

  .retail-text-red {
    color: var(--ca-retail-error);
  }

  .reail-text-success {
    color: var(--ca-retail-success);
  }
}

html[data-theme='dark-retail'] {
  %fordAntennaCond {
    font-family: $base-font-condensed;
    line-height: 48px;
    letter-spacing: 2.43px;
  }

  %fordAntennaCondMed {
    font-family: $base-font-condensed-med;
    letter-spacing: 2.43px;
    line-height: 1.2;
  }

  // text color class names
  .text-gray {
    color: var(--font-color-primary);
  }

  .text-green {
    color: var(--alert-success);
  }

  .text-orange {
    color: var(--alert-warning);
  }

  .text-red {
    color: var(--alert-error);
  }

  .text-blue {
    // color: var(--font-color-secondary);
  }

  .text-disabled {
    color: var(--alert-disabled);
  }

  .text-dark-orange {
    color: var(--instructions-text);
  }

  .text-blue-muted {
    color: var(--font-color-quinary);
  }

  .text-light-gray {
    color: #767676;
  }

  // text styles
  .text-base--16 {
    @extend %fordAntennaCond;
    font-size: 16px;
  }

  .text-base--18 {
    @extend %fordAntennaCond;
    font-size: 18px;
  }

  .text-base--20 {
    @extend %fordAntennaCond;
    font-size: 20px;
  }

  .text-base--22 {
    @extend %fordAntennaCond;
    font-size: 22px;
  }

  .text-base--26 {
    @extend %fordAntennaCond;
    font-size: 26px;
  }

  .text-base--31 {
    @extend %fordAntennaCond;
    font-size: 31px;
  }

  .text-base--34 {
    @extend %fordAntennaCond;
    font-size: 34px;
  }

  .text-base--34--bolded {
    @extend %fordAntennaCondMed;
    font-size: 34px;
  }

  .text-base--40 {
    @extend %fordAntennaCond;
    font-size: 40px;
  }

  .text-base--40--bolded {
    @extend %fordAntennaCondMed;
    font-size: 40px;
  }

  .text-base--36 {
    @extend %fordAntennaCond;
    font-size: 36px;
    line-height: 55px;
    letter-spacing: 0px;
  }

  .text-base--36--bolded {
    @extend %fordAntennaCondMed;
    font-size: 36px;
  }

  .text-base--46 {
    @extend %fordAntennaCond;
    font-size: 46px;
    line-height: 1.2;
    letter-spacing: normal;
  }

  .filter-text {
    @extend %fordAntennaCond;
    font-size: 34px;
    letter-spacing: 0px;
    text-transform: capitalize;
  }

  .filter-sub-text {
    @extend %fordAntennaCond;
    font-size: 24px;
    letter-spacing: 0px;
    margin-left: 56px;
    white-space: nowrap;
  }

  // headline styles
  .text-headline {
    @extend %fordAntennaCond;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 2.43px;
    line-height: initial;
    padding-bottom: 0px;
  }

  .text-headline--padding-40 {
    @extend .text-headline;
    padding-bottom: 40px;
  }

  .text-headline--padding-30 {
    @extend .text-headline;
    padding-bottom: 30px;
  }

  //search input font styles
  .search-input-text {
    color: var(--font-color-quinary);
    font-family: $base-font-condensed;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 55px;
    font-weight: 500;
    height: 70px !important;
    padding-left: 20px;
    text-transform: none;
  }

  .text-error {
    @extend .text-base--40--bolded;
    @extend .text-red;
    text-align: center;
    margin-bottom: 35px;
  }

  .mutedBlue-divider-small {
    @extend .mutedBlue-divider;
    margin-top: 16px;
  }

  .mutedBlue-divider-small {
    background-color: var(--gray-divider);
  }

  .filter-sub-text {
    color: $secondaryGrayDarkMode;
  }

  .retail-text-red {
    color: var(--ca-retail-error);
  }

  .reail-text-success {
    color: var(--ca-retail-success);
  }
}
