html[data-theme='light-retail'] {
  .search {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    padding-bottom: 25px;

    &__container {
      position: relative;
      z-index: 2;
    }

    & ::-webkit-scrollbar {
      display: none;
    }
  }

  //***** floating-input label text *****//
  .floating-input {
    @extend .search-input-text;
    font-family: $base-font-condensed;
    display: block;
    position: relative;
    padding: 12px 104px;
    width: 100%;
    height: 55px;
    z-index: 3;
    background-color: var(--background-color-primary);
    box-shadow: inset 0 -5px 8px 0 rgba(255, 255, 255, 0.5), inset 0 5px 8px 0 rgba(52, 60, 77, 0.4),
      0 -10px 15px 0 rgba(255, 255, 255, 0.75), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid $white;
    border-radius: var(--btn-border-radius);
    text-transform: capitalize;

    &::-webkit-input-placeholder,
    &::placeholder {
      @extend .search-input-text;
      position: absolute;
      top: 12.5%;
      left: 85px;
    }
  }

  .r_floating-input-placeholder {
    &::placeholder {
      color: var(--placeholder-text-color) !important;
    }
  }

  //***** Search Input Icons *****//
  .search-icon {
    position: absolute;
    top: 53%;
    @include transform(translateY(-50%));
    height: 42px;
    width: 42px;
    left: 40px;
    z-index: 4;
  }

  .clear-input-icon {
    position: absolute;
    top: 53%;
    @include transform(translateY(-50%));
    right: 25px;
    z-index: 4;
    width: 60px;
    height: 42px;
  }

  .clear-input-icon-hidden {
    display: none;
  }

  //***** Autosuggestion drop down *****//
  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    border-top: none;
    padding-top: 35px;
    background-color: var(--background-color-primary);
    z-index: 1;
    border-radius: var(--btn-border-radius);
    box-shadow: inset 0 -5px 8px 0 rgba(255, 255, 255, 0.5), inset 0 5px 8px 0 rgba(52, 60, 77, 0.4),
      0 -10px 15px 0 rgba(255, 255, 255, 0.75), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    max-height: 620px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin-top: 6%;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 35px;
  }

  .react-autosuggest__suggestion--highlighted {
    background: var(--btn-secondary-bkgd-color);
  }

  .react-autosuggest__suggestion--highlighted .hightitle {
    color: $white;
  }

  .react-autosuggest__suggestion--highlighted .suggestion__details {
    color: $white;
  }

  .hightitle {
    color: var(--font-color-quinary);
    font-family: $base-font-condensed;
    font-size: 36px;
    line-height: 55px;
    letter-spacing: 0px;
  }

  .suggestion__details {
    font-family: $base-font-condensed-light;
    color: var(--font-color-quinary);
    text-transform: uppercase;
    opacity: 0.75;
    font-size: 1.5rem;
    letter-spacing: 2px;
  }

  .recent-searches {
    @extend .hightitle;
    @extend .react-autosuggest__suggestion;
    color: var(--font-color-primary);
    margin-top: 6%;
    margin-bottom: -6%;
  }
  .search-input-disabled {
    background-color: var(--disabled-background-color);
  }
}

html[data-theme='dark-retail'] {
  .search {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    padding-bottom: 25px;

    &__container {
      position: relative;
      z-index: 2;
    }

    & ::-webkit-scrollbar {
      display: none;
    }
  }

  //***** floating-input label text *****//
  .floating-input {
    @extend .search-input-text;
    font-family: $base-font-condensed;
    display: block;
    position: relative;
    padding: 12px 104px;
    width: 100%;
    height: 55px;
    z-index: 3;
    background-color: var(--background-color-primary);
    box-shadow: inset 0 -5px 8px 0 rgba(255, 255, 255, 0.5), inset 0 5px 8px 0 rgba(52, 60, 77, 0.4),
      0 -10px 15px 0 rgba(255, 255, 255, 0.75), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid $white;
    border-radius: var(--btn-border-radius);
    text-transform: capitalize;

    &::-webkit-input-placeholder,
    &::placeholder {
      @extend .search-input-text;
      position: absolute;
      top: 12.5%;
      left: 85px;
    }
  }

  .r_floating-input-placeholder {
    &::placeholder {
      color: var(--placeholder-text-color) !important;
    }
  }

  //***** Search Input Icons *****//
  .search-icon {
    position: absolute;
    top: 53%;
    @include transform(translateY(-50%));
    height: 42px;
    width: 42px;
    left: 40px;
    z-index: 4;
  }

  .clear-input-icon {
    position: absolute;
    top: 53%;
    @include transform(translateY(-50%));
    right: 25px;
    z-index: 4;
    width: 60px;
    height: 42px;
  }

  .clear-input-icon-hidden {
    display: none;
  }

  //***** Autosuggestion drop down *****//
  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    border-top: none;
    padding-top: 35px;
    background-color: var(--background-color-primary);
    z-index: 1;
    border-radius: var(--btn-border-radius);
    box-shadow: inset 0 -5px 8px 0 rgba(255, 255, 255, 0.5), inset 0 5px 8px 0 rgba(52, 60, 77, 0.4),
      0 -10px 15px 0 rgba(255, 255, 255, 0.75), 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    max-height: 620px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin-top: 6%;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 35px;
  }

  .react-autosuggest__suggestion--highlighted {
    background: var(--btn-secondary-bkgd-color);
  }

  .react-autosuggest__suggestion--highlighted .hightitle {
    color: $white;
  }

  .react-autosuggest__suggestion--highlighted .suggestion__details {
    color: $white;
  }

  .hightitle {
    color: var(--font-color-quinary);
    font-family: $base-font-condensed;
    font-size: 36px;
    line-height: 55px;
    letter-spacing: 0px;
  }

  .suggestion__details {
    font-family: $base-font-condensed-light;
    color: var(--font-color-quinary);
    text-transform: uppercase;
    opacity: 0.75;
    font-size: 1.5rem;
    letter-spacing: 2px;
  }

  .recent-searches {
    @extend .hightitle;
    @extend .react-autosuggest__suggestion;
    color: var(--font-color-primary);
    margin-top: 6%;
    margin-bottom: -6%;
  }

  .floating-input {
    box-shadow: var(--floating-input-shadow);
    border: none;
  }

  .react-autosuggest__suggestions-container--open {
    box-shadow: var(--autosuggest-boxshadow);
    border: none;
  }
  .search-input-disabled {
    background-color: var(--disabled-background-color);
  }
}
