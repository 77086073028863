//
//  Button Styles
//  ---------------------------------------------

html[data-theme='light-retail'] {
  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  %btn-base {
    font-family: $base-font-condensed;
    font-size: 36px;
    letter-spacing: normal;
    line-height: 1.2;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: visible;
    border: none;
    border-radius: var(--btn-border-radius);
    padding: 9px 88px 9px 88px;
    text-align: center;
    cursor: pointer;
  }

  %tag-base {
    font-family: $base-font-condensed;
    font-size: 31px;
    letter-spacing: 2.21px;
    line-height: 1.19;
    white-space: nowrap;
    color: var(--font-color-tertiary);
    background-color: var(--background-color-tertiary);
    border-radius: var(--btn-border-radius);
    text-align: center;
  }

  %tag-pNc-base {
    margin-right: 37px;
    color: var(--btn-primary-color);
    background-color: var(--background-color-primary);
    font-size: 34px;
    letter-spacing: 2.214px;
    padding: 1px 22px;
  }

  .button {
    &__icon {
      &--left {
        margin-right: 0.7rem;
        width: 40px;
        height: 40px;
        padding-bottom: 8px;
      }
    }

    &__left {
      position: absolute;
      left: 10px;
    }

    &__right {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  .button--primary {
    @extend %btn-base;
    color: var(--btn-primary-color);
    background-color: var(--btn-primary-bkgd-color);
    box-shadow: var(--btn-primary-drop-shadow);

    &:hover {
      text-decoration: none;
      color: var(--btn-primary-color);
    }
  }

  .button--secondary {
    @extend %btn-base;
    color: var(--btn-secondary-color);
    background: var(--button-secondary-bkgd-color);
    box-shadow: var(--box-shadow-color-secondary);

    &:hover {
      text-decoration: none;
      color: var(--btn-secondary-color);
    }
  }

  .button--tertiary {
    @extend .button--primary;
    padding: 11px 35px 10px;
    min-width: 260px;
    height: 62px;
    position: relative;
    top: 4px;
  }

  .button--close {
    position: absolute;
    top: 7px;
    left: 15px;
    height: 50px;
    width: 50px;
    padding: 10px;
  }

  .button--disabled {
    @extend %btn-base;
    background: var(--disabled-background-color);
    box-shadow: 0 -10px 10px 0 rgba(255, 255, 255, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.01);
    border: 1px solid #ffffff;
    opacity: 0.8;
    min-width: 282px;

    &:hover {
      text-decoration: none;
    }
  }

  .button--primary--faded {
    @extend .button--primary;
    opacity: 0.5;
  }

  .rounded-btn {
    z-index: 1;
    // max-width: 48px;
    // max-height: 48px;
    height: 50px;
    width: 56px;
    border-radius: 50%;
    background: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    left: 95%;
    top: 87%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    line-height: 46px;
  }

  .filter_btn {
    height: 50px;
    min-width: 100px;
    font-size: 28px;
    top: 0px;
    margin-right: 2rem;
    width: 160px;
    padding: 10px 0px;
  }

  .retail-btn-secondary {
    @extend %btn-base;
    padding: 8px 32px;
    color: var(--btn-secondary-color);
    background: var(--button-secondary-bkgd-color);
    box-shadow: var(--box-shadow-color-secondary);

    &:hover {
      text-decoration: none;
      color: var(--btn-secondary-color);
    }
  }
}

html[data-theme='dark-retail'] {
  .button--tertiary {
    box-shadow: var(--btn-primary-drop-shadow);
  }

  .button--disabled {
    border: 1px solid $secondaryGrayDarkMode;
    box-shadow: none;
    background: var(--background-color-quaternary);
    color: $lightestGrey;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  %btn-base {
    font-family: $base-font-condensed;
    font-size: 36px;
    letter-spacing: normal;
    line-height: 1.2;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: visible;
    border: none;
    border-radius: var(--btn-border-radius);
    padding: 9px 88px 9px 88px;
    text-align: center;
    cursor: pointer;
  }

  %tag-base {
    font-family: $base-font-condensed;
    font-size: 31px;
    letter-spacing: 2.21px;
    line-height: 1.19;
    white-space: nowrap;
    color: var(--font-color-tertiary);
    background-color: var(--background-color-tertiary);
    border-radius: var(--btn-border-radius);
    text-align: center;
  }

  %tag-pNc-base {
    margin-right: 37px;
    color: var(--btn-primary-color);
    background-color: var(--background-color-primary);
    font-size: 34px;
    letter-spacing: 2.214px;
    padding: 1px 22px;
  }

  .button {
    &__icon {
      &--left {
        margin-right: 0.7rem;
        width: 40px;
        height: 40px;
        padding-bottom: 8px;
      }
    }

    &__left {
      position: absolute;
      left: 10px;
    }

    &__right {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  .button--primary {
    @extend %btn-base;
    color: var(--btn-primary-color);
    background-color: var(--btn-primary-bkgd-color);
    box-shadow: var(--btn-primary-drop-shadow);

    &:hover {
      text-decoration: none;
      color: var(--btn-primary-color);
    }
  }

  .button--secondary {
    @extend %btn-base;
    color: var(--btn-secondary-color);
    background: var(--button-secondary-bkgd-color);
    box-shadow: var(--box-shadow-color-secondary);

    &:hover {
      text-decoration: none;
      color: var(--btn-secondary-color);
    }
  }

  .button--tertiary {
    @extend .button--primary;
    padding: 11px 35px 10px;
    min-width: 260px;
    height: 62px;
    position: relative;
    top: 4px;
  }

  .button--close {
    position: absolute;
    top: 7px;
    left: 15px;
    height: 50px;
    width: 50px;
    padding: 10px;
  }

  .button--disabled {
    @extend %btn-base;
    background: var(--disabled-background-color);
    box-shadow: 0 -10px 10px 0 rgba(255, 255, 255, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.01);
    border: 1px solid #ffffff;
    opacity: 0.8;
    min-width: 282px;

    &:hover {
      text-decoration: none;
    }
  }

  .button--primary--faded {
    @extend .button--primary;
    opacity: 0.5;
  }

  .rounded-btn {
    z-index: 1;
    // max-width: 48px;
    // max-height: 48px;
    height: 50px;
    width: 56px;
    border-radius: 50%;
    background: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    left: 95%;
    top: 87%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    line-height: 46px;
  }

  .filter_btn {
    height: 50px;
    min-width: 100px;
    font-size: 28px;
    top: 0px;
    margin-right: 2rem;
    width: 160px;
    padding: 10px 0px;
    box-shadow: 0 10px 10px 0 #000, 0 -10px 10px 0 #293443;
  }

  .retail-btn-secondary {
    @extend %btn-base;
    padding: 8px 32px;
    color: var(--btn-secondary-color);
    background: var(--button-secondary-bkgd-color);
    box-shadow: var(--box-shadow-color-secondary);

    &:hover {
      text-decoration: none;
      color: var(--btn-secondary-color);
    }
  }
}

.payment_modal_text_btn {
  align-items: center;
  display: flex;
  font-family: $base-font-condensed;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  text-align: center;
}

.modal_primary_btn {
  @extend .retail-btn-secondary;
  @extend .payment_modal_text_btn;
  color: $white;
  width: 304px;
  height: 62px;
}

.unable-to-active-error-modal-close-btn {
  @extend .modal_primary_btn;
  margin-top: 80px;
}

.disclaimer_modal_close_btn {
  @extend .modal_primary_btn;
  @extend .payment_modal_text_btn;
  margin-top: 45px;
}

.charging_speed_modal_close_btn {
  @extend .retail-btn-secondary;
  @extend .payment_modal_text_btn;
  color: $white;
  width: 304px;
  height: 62px;
  margin-top: 45px;
}
