//
//  Icons
//  ---------------------------------------------

html[data-theme='light-retail'] {
  %arrow-icon {
    display: inline-block;
    border-width: 0 2px 2px 0;
    padding: 9px;
    border-style: solid;
    outline: none;
  }

  .icon-arrow-down {
    @extend %arrow-icon;
    border-color: var(--arrow-down);
    @include transform(rotate(45deg));
  }

  .icon-arrow-up {
    @extend %arrow-icon;
    border-color: var(--arrow-up);
    @include transform(rotate(225deg));
  }

  .filter-icon {
    height: 10px;
    width: 14px;
  }

  .distance-icon-button {
    height: 10px;
    width: 17px;
  }
}

html[data-theme='dark-retail'] {
  %arrow-icon {
    display: inline-block;
    border-width: 0 2px 2px 0;
    padding: 9px;
    border-style: solid;
    outline: none;
  }

  .icon-arrow-down {
    @extend %arrow-icon;
    border-color: var(--arrow-down);
    @include transform(rotate(45deg));
  }

  .icon-arrow-up {
    @extend %arrow-icon;
    border-color: var(--arrow-up);
    @include transform(rotate(225deg));
  }

  .filter-icon {
    height: 10px;
    width: 14px;
  }

  .distance-icon-button {
    height: 10px;
    width: 17px;
  }
}
