.driversDistractionBanner {
  height: 140px;
  background-color: var(--background-color-primary);
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-left: 52px;
  color: var(--font-color-quinary);
  z-index: 1;
  button {
    text-transform: none;
  }
}

html[data-theme='dark-retail'] {
  .driversDistractionBanner {
    color: var(--btn-secondary-color);
    border: none;
  }
}
