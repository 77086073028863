//
//  React Modal Styles
//  ---------------------------------------------
/****note: refactor after the ****/

html[data-theme='light-retail'] {
  .ReactModal {
    &__Overlay {
      position: fixed;
      inset: 0px;
      background-color: var(--modal-overlay) !important;
      z-index: 998;
      display: flex;
      align-items: center;
    }

    &__Content {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      align-items: center;
      margin: 0 auto;
      width: 58%;
      height: auto;
      max-height: 100%;
      position: relative;
      padding: 0;
      overflow: scroll;
      border: 0;
      border-radius: 3px;
      background-color: var(--background-color-primary);
      box-shadow: var(--modal-box-shadow);
    }
  }

  body.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
  }

  .modal-header-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .modal-title-text {
    height: 34px;
    color: var(--font-color-secondary);
  }

  .modal-body-text {
    height: 104px;
    color: var(--font-color-primary);
  }

  .modal-subtitle-text {
    color: var(--font-color-primary);
    @extend %fordAntennaCond;
    height: 26px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 26px;
  }

  .modal-header-shadow {
    box-shadow: var(--modal-header-shadow);
  }

  .stop-charger-modal {
    height: auto;
    max-height: none;
  }

  .modal-footer {
    @extend .modal-header-sticky;
    bottom: 0;
    justify-content: center;
    background-color: var(--background-color-primary);
  }

  .modal {
    &__header {
      box-shadow: var(--modal-header-shadow);
      padding-top: 15px;
      padding-bottom: 12px;

      &-title {
        @extend .text-base--46;
        @extend .text-blue-muted;
        text-align: center;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;
      padding: 53px 40px;
      overflow: auto;

      &-content {
        // padding-bottom: 20px;
      }

      &-btn {
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        button,
        a:not(:first-of-type) {
          margin-top: 35px;
        }
      }
    }

    &__footer {
      padding-bottom: 10%;
      padding-top: 2%;
      text-align: center;
    }
  }
}

html[data-theme='dark-retail'] {
  .ReactModal {
    &__Overlay {
      position: fixed;
      inset: 0px;
      background-color: var(--modal-overlay) !important;
      z-index: 998;
      display: flex;
      align-items: center;
    }

    &__Content {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      align-items: center;
      margin: 0 auto;
      width: 58%;
      height: auto;
      max-height: 100%;
      position: relative;
      padding: 0;
      overflow: scroll;
      border: 0;
      border-radius: 3px;
      background-color: var(--background-color-primary);
      box-shadow: var(--modal-box-shadow);
    }
  }

  body.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
  }

  .modal-header-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .modal-title-text {
    height: 34px;
    color: var(--font-color-secondary);
  }

  .modal-body-text {
    height: 104px;
    color: var(--font-color-primary);
  }

  .modal-subtitle-text {
    color: var(--font-color-primary);
    @extend %fordAntennaCond;
    height: 26px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 26px;
  }

  .modal-header-shadow {
    box-shadow: var(--modal-header-shadow);
  }

  .stop-charger-modal {
    height: auto;
    max-height: none;
  }

  .modal-footer {
    @extend .modal-header-sticky;
    bottom: 0;
    justify-content: center;
    background-color: var(--background-color-primary);
  }

  .modal {
    &__header {
      box-shadow: var(--modal-header-shadow);
      padding-top: 15px;
      padding-bottom: 12px;

      &-title {
        @extend .text-base--46;
        @extend .text-gray;
        text-align: center;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;
      padding: 53px 40px;
      overflow: auto;

      &-content {
        // padding-bottom: 20px;
      }

      &-btn {
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        button,
        a:not(:first-of-type) {
          margin-top: 35px;
        }
      }
    }

    &__footer {
      padding-bottom: 10%;
      padding-top: 2%;
      text-align: center;
    }
  }
}
