//
//  Base
//  ---------------------------------------------

html[data-theme='light-retail'] {
  *,
  *:after,
  *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }

  html {
    font-size: 16px;
  }

  body {
    background-color: var(--background-color-primary);
    color: var(--font-color-primary);
    margin: 0;
    padding: 0;
    font-family: $base-font-condensed;
    font-size: 34px;
    letter-spacing: 2.43px;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: contain;
  }

  html,
  body,
  #root {
    height: 100%;
    position: relative;
    outline: none;
  }

  #root {
    overflow-x: hidden;
  }

  h1,
  h4,
  h3,
  h5,
  h6 {
    font-family: $base-font-condensed;
  }

  h1 {
    margin: 0;
    padding: 0;
    outline: none;
  }

  h2 {
    font-family: $base-font-condensed-extra-light;
    font-size: 2.1875rem;
    outline: none;
  }

  h3 {
    margin-bottom: 1.875rem;
    outline: none;
  }

  h4 {
    margin-bottom: 0.625rem;
    outline: none;
  }

  h6 {
    margin-bottom: 0;
    outline: none;
  }

  code {
    font-family: $code-font;
    outline: none;
  }

  a {
    color: var(--font-color-primary);
    font-size: inherit;
    font-family: inherit;
    outline: none;
    text-decoration: none;
  }

  p {
    a {
      text-decoration: underline;
      outline: none;
      color: var(--font-color-secondary);
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
    outline: none;
  }

  figcaption {
    @extend %fordAntennaCondMed;
    text-transform: uppercase;
    margin: 60px 0 30px;
  }
}

html[data-theme='dark-retail'] {
  *,
  *:after,
  *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }

  html {
    font-size: 16px;
  }

  body {
    background-color: var(--background-color-primary);
    color: var(--font-color-primary);
    margin: 0;
    padding: 0;
    font-family: $base-font-condensed;
    font-size: 34px;
    letter-spacing: 2.43px;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: contain;
  }

  html,
  body,
  #root {
    height: 100%;
    position: relative;
    outline: none;
  }

  #root {
    overflow-x: hidden;
  }

  h1,
  h4,
  h3,
  h5,
  h6 {
    font-family: $base-font-condensed;
  }

  h1 {
    margin: 0;
    padding: 0;
    outline: none;
  }

  h2 {
    font-family: $base-font-condensed-extra-light;
    font-size: 2.1875rem;
    outline: none;
  }

  h3 {
    margin-bottom: 1.875rem;
    outline: none;
  }

  h4 {
    margin-bottom: 0.625rem;
    outline: none;
  }

  h6 {
    margin-bottom: 0;
    outline: none;
  }

  code {
    font-family: $code-font;
    outline: none;
  }

  a {
    color: var(--font-color-primary);
    font-size: inherit;
    font-family: inherit;
    outline: none;
    text-decoration: none;
  }

  p {
    a {
      text-decoration: underline;
      outline: none;
      color: var(--font-color-secondary);
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
    outline: none;
  }

  figcaption {
    @extend %fordAntennaCondMed;
    text-transform: uppercase;
    margin: 60px 0 30px;
  }
}
