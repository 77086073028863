.stop-button-width {
  min-width: 307px !important;
}

html[data-theme='dark-retail'] {
  .stopButtonContainer .button--secondary {
    background: var(--button-secondary-bkgd-color);
    padding: 0px !important;
    height: 62px;
    width: 307px;
    box-shadow: none;
    color: var(--btn-secondary-color);
  }

  .plug-icon {
    padding-right: 8px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
}
